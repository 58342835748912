import { useEffect, useRef } from 'react';
import type { PublicAPIResponse } from '~/public-api/common/types';
import type { UseToastOptions } from '@chakra-ui/react';

export const useSubmitFetcherOnError = <
  T extends Awaited<PublicAPIResponse<any>> | undefined
>({
  onError,
  fetcherData,
  fetcherState,
}: {
  fetcherData: T;
  fetcherState: 'idle' | 'loading' | 'submitting';
  onError: (
    fetcherData: { statusCode: number; statusText: string },
    fetcherResponse: T
  ) => void;
} & UseToastOptions) => {
  const evaluated = useRef<boolean>(false);

  useEffect(() => {
    if (fetcherState === 'submitting') {
      evaluated.current = false;
    }

    if (!fetcherData || evaluated.current || fetcherState !== 'idle') {
      return;
    }

    if (fetcherData.ok === false && !evaluated.current) {
      evaluated.current = true;
      onError(
        {
          statusCode: fetcherData.statusCode,
          statusText: fetcherData.statusText,
        },
        fetcherData
      );
    }
  }, [fetcherData, fetcherState, onError]);
};
